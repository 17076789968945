import api from "./interceptor";
import {xAuth,baseUrl,service,hash} from "../utils/constant";
import axios from "axios";


const error={"code":400,"message":"Internet not connected on your device."};
const token = localStorage.getItem('token');
const headers = {
    'Content-Type': 'application/json',
    'x-auth': xAuth
  }

export const sub = async (userData) => {
    try {       
        const response = await axios.post(`${baseUrl}/user/sub`, userData,
        {headers:headers});
        return response;
    } catch (e) {
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};
export const sublba = async (userData) => {
    try {
       
        const response = await axios.post(`${baseUrl}/user/sublba`, userData,
        {headers:headers});
        return response;
    } catch (e) {
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};


export const subsl = async (userData) => {
    try {
       
        const response = await axios.post(`${baseUrl}/user/subsl`, userData,
        {headers:headers});
        return response;
    } catch (e) {
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};

export const unsub = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;       
       if(service=="lbwa") //botswana
        {
            const response = await axios.post(`${baseUrl}/user/sub`, userData,
            {headers:headers});
            return response;
        }
        else if(service=="lba") //liberia
        {
            const response = await axios.post(`${baseUrl}/user/sublba`, userData,
            {headers:headers});
            return response;
        }
        else if(service=="sl") //SIERRALEONE
        {
            const response = await axios.post(`${baseUrl}/user/subsl`, userData,
            {headers:headers});
            return response;
        }
    } catch (e) {        
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};


export const subApi = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;   
        let finalUrl=baseUrl;   
        if(service==="ocm")finalUrl=finalUrl+'/user/orange'; 
        else if(service==="mtnz")finalUrl=finalUrl+'/user/mtn';
        else if(service==="ogb")finalUrl=finalUrl+'/user/ogb';
        else if(service==="lbwa")finalUrl=finalUrl+'/user/sub';
        else if(service==="lba")finalUrl=finalUrl+'/user/sublba';
        else if(service==="sl")finalUrl=finalUrl+'/user/subsl';
        else if(service==="mtnet")finalUrl=finalUrl+'/user/mtnet';

        const response = await axios.post(`${finalUrl}`, userData,
        {headers:headers});
        return response;
    } catch (e) {        
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};

export const unsubApi = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;   
        let finalUrl=baseUrl;   
        if(service==="ocm")finalUrl=finalUrl+'/user/orange'; 
        else if(service==="mtnz")finalUrl=finalUrl+'/user/mtn';
        else if(service==="ogb")finalUrl=finalUrl+'/user/ogb';
        else if(service==="lbwa")finalUrl=finalUrl+'/user/sub';
        else if(service==="lba")finalUrl=finalUrl+'/user/sublba';
        else if(service==="sl")finalUrl=finalUrl+'/user/subsl';
        else if(service==="mtnet")finalUrl=finalUrl+'/user/mtnet';

        const response = await axios.post(`${finalUrl}`, userData,
        {headers:headers});
        return response;
    } catch (e) {        
        if(e.response!=null && e.response!==undefined) throw setResponse(e);
        else throw error;
    }
};

export const getRanking = async () => {
    try {
        headers.Authorization='Bearer '+token;
        const response = await axios.post(`${baseUrl}/user/list_user_time`,'',
            { headers: headers})
        return response;
    } catch (e) {
        if(e.response!=null && e.response!==undefined) throw e.response;
        else throw error;
    }
}

export const checkStatus = async () => {
    try {
        headers.Authorization='Bearer '+token;
        const response = await axios.get(`${baseUrl}/user/checkStatus`,
            { headers: headers})
        return response;
    } catch (e) {
        if(e.response!=null && e.response!==undefined) throw setResponse(e);
        else throw error;
    }
}

export const evinaApi = async () => {
    try {
        var url ="https://mtnz.uvcanngaming.com/he/secure.php";
        if(service==="mtnet")url ="https://met.uvcanngaming.com/he/secure.php";
        
        const response = await axios.get(url,{});
        return response;
        /*var url ="https://api.dcbprotect.com/uvcann_mtn_zm/script";
        var currentTime=Date.now();
        var uid="10"+Date.now();
        var user="uvcann_mtn_zm";
        var pass="HDuT1CrN7QoSCFAbFpAv7BlFavZ9sxok";
        var param="ti="+uid+"&te="+encodeURIComponent("#cta_button")+"&ts="+currentTime+"&country=ZM&carrier=64502&service=UVGAMING&pl="+encodeURIComponent("[\"mtn_zm\"]");
      

        await  hash('SHA-256',user+param+pass).then((signature) => {
            console.log(signature);
            param=param+"&s="+signature;
            url=url+"?"+param;
            const response =  axios.get(url,{ headers: headers});
            return response;
        });
        */
        
    } catch (e) {
        if(e.response!=null && e.response!==undefined) throw setResponse(e);
        else throw error;
    }
}



function setResponse(e)
{   
    if(e.response.status===401){
        localStorage.clear();
        window.location.href ="/login";
    }
    else {return e.response.data;}
}
