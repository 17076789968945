import React, { useState, useEffect,useContext } from 'react'
import '../css/style.css';
import '../css/login1.css';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { useNavigate,useSearchParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {subApi,evinaApi} from '../../services/user-api';
import {serviceId,Loader,countryCode,flag,Random} from "../../utils/constant";

const Mtnz = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [flowLoading, setFlowLoading] = useState(false); 
    const [alreadySub, setAlreadySub] = useState(false);   
    const [flow, setFlow] = useState('');   
    const [phone, setPhone] = useState(localStorage.getItem("ugAlias"));
    const [subOption, setSubOption] = useState(1);
    const [plan, setPlan] = useState(1);
    const [price, setPrice] = useState(["0","K2/Day","K5/Week","K15/Month"]);
    const [message, setMessage] = useState({message: "",error: false});
    const [otpId, setOtpId] = useState('');
    const [otp, setOtp] = useState('');
    const [ButtonDisabled, setButtonDisabled] = useState(true);
    const [evina, setEvina] = useState("0"); //EVINA 
    const [q] = useSearchParams();
    const [uniqueId, setUniqueId] = useState('');
    const [subMode, setSubMode] = useState('WAP');
 

    useEffect(() => {               
        setMessage({message: "",error: false});
        var uuid=Random();var submd="WAP";
        if(q.get("uid")){uuid=q.get("uid");submd="APP";setUniqueId(uuid);setSubMode(submd);console.log("phone="+phone+":"+uuid);} 
        else {setUniqueId(uuid);console.log("phone="+phone+"::"+uuid);}   
        if (phone) {
            setFlowLoading(true); 
            subApi({action:0,ani: phone,serviceId:serviceId,mode:submd,uid:uuid,alias:evina}).then((res) => {
               // console.log(res);                 
                if(res.data.data!==null && res.data.data!==undefined)
                {   
                    if(res.data.responseCode===202) // Already Subscribed
                    {
                        setFlowLoading(false);
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                    }
                    else if(res.data.responseCode===203) // for Evina script
                    {
                        const scriptTag=document.createElement('script');
                        scriptTag.innerHTML=res.data.data;
                        document.body.appendChild(scriptTag);
                        var event = new Event('DCBProtectRun');
                        document.dispatchEvent(event);
                        setFlowLoading(false);
                        setFlow("PACK");
                    }
                    else{
                        setFlowLoading(false);
                        setFlow("PACK");
                    }
                }
                else
                {
                    setFlowLoading(false);
                    setFlow("PACK");//
                }
            }).catch((err) => {
                console.log(err);
                setFlowLoading(false);
                setFlow("PACK");
            });
        }else
        {
         setEvina("0");
         setFlowLoading(false);
        }
       /* 
        setFlowLoading(true);
       evinaApi().then((res) => {
            console.log(res.data); 
            if(res.data!==null && res.data!==undefined && res.data.s!==undefined)
            {
                const scriptTag=document.createElement('script');
                scriptTag.innerHTML=res.data.s;
                document.body.appendChild(scriptTag);
                var event = new Event('DCBProtectRun');
                document.dispatchEvent(event);
                checkStatus();
            }
            else 
            {
                checkStatus();
            }
            
        }).catch((err) => {
            console.log(err);
            checkStatus();
        });
         */       
    }, []);   

    const checkStatus = () =>{
        console.log("phone="+phone); 
        if (phone) {
            setFlowLoading(true); 
            subApi({action:0,ani: phone,serviceId:serviceId,mode:subMode,uid:uniqueId}).then((res) => {
               // console.log(res);                 
                if(res.data.data!==null && res.data.data!==undefined)
                {
                    if(res.data.responseCode===202) // Already Subscribed
                    {
                        setFlowLoading(false);
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                    }else setFlowLoading(false);
                } else setFlowLoading(false);
            }).catch((err) => {
                console.log(err);
                setFlowLoading(false);
            });
        }else setFlowLoading(false);  
    }
    const handleNumberChange = async(event) =>{
        const input = event.target.value;
        if(input.length<11)setPhone(input);
        setButtonDisabled(input.length <8);
    }  
    const handleRadioChange = async(input) =>{
        setPlan(input);
    }  
    const handleSubChange = async(input) =>{
        setSubOption(input);
    }    
    const handleSubOptionClick = async() => {
        if(subOption==2){setPlan(3);}
        else {setPlan(1);}
        setFlow("PACK");
    }
    const cancel = async(input) => {
        setMessage({message: "",error: false});
        setFlow(input);
    }
    const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
    }
    const redirectTo = async(input) => {
        if(input==="/home")
        {
            checkStatus();
            setFlow("SUCCESS");
        }
        else navigate(input);
      };

    const handleButtonClick = async() => {
        //console.log(phone);
        setLoading(true);
        await subApi({action:1,serviceId:serviceId,planId:plan,ani:phone,mode:subMode,uid:uniqueId,alias:evina}).then((res) => {            
            console.log(res)
            if(res.data.data!==null && res.data.data!==undefined)
            {   
                if(res.data.responseCode===202) // already sub
                {
                    localStorage.setItem("token", res.data.data.accessToken);
                    setFlow("SUCCESS");
                }
                else setFlow("SUCCESS");
            }else setFlow("SUCCESS");
            
        }).catch((err) => {
            console.log(err)
            setMessage({message: err.message,error: true})
        }).finally(() => setLoading(false))
      };

           
    const sendOtp = async() => {
        console.log('phone='+phone);
        setMessage({message: "",error: false});
        if(phone && phone.length>=8)
        {
            var nm=phone;if(nm.length<=10)nm=countryCode+nm
            setLoading(true);
           // await subApi({action:3,serviceId:serviceId,ani: nm}).then((res) => { 
            subApi({action:0,ani: nm,serviceId:serviceId,mode:subMode,uid:uniqueId}).then((res) => {     
                if(res.status===200)
                { 
                    localStorage.setItem("token", res.data.data.accessToken);
                    window.location.href ="/home";
                    /*if(res.data.responseCode===202) // already subscribed 
                    {
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                        //setAlreadySub(true);setFlow("SUCCESS");
                    }
                    else  setFlow("PACK"); */
                }               
                else {setFlow("PACK");}
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setFlow("PACK");
                //setMessage({message: err.message, error: true });
                setLoading(false);
            });
        }
        else if(phone && phone.length>0){setMessage({message: "Please enter a valid number",error: true})}
        else{setMessage({message: "Please enter a number",error: true})}
       
       // navigate('/profile');
      };
    
      const confirmOtp = async() => {
        setMessage({message: "",error: false});     
        if(otp!=null && otp.length===4)
        {
            var nm=phone;if(nm.length<=8)nm=countryCode+nm
            setLoading(true);
            await subApi({action:4,serviceId:serviceId,ani:nm,otpId:otpId,otp:otp,mode:subMode,uid:uniqueId}).then((res) => {                    
                if(res.status===200)
                { 
                    if(res.data.responseCode===202) // already subscribed 
                    {
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                        //setAlreadySub(true);setFlow("SUCCESS");
                    }
                    else
                    {
                        localStorage.setItem("ugAlias",nm);
                        setFlow("DOUBLECONFIRM");
                    }                    
                }                
                else {setMessage({message: res.data.message,error: true})}
            }).catch((err) => {
                setMessage({message: err.message, error: true});
            }).finally(() => setLoading(false))
        }
        else{setMessage({ message:"OTP is not valid",error: true });}
      }     
      //{(evinaScript && evinaScript.length>20) && <script type="text/javascript">{evinaScript}</script>}

    return (  
        flowLoading? <Loader />
        :<>       
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login">               
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">
                            Dive into the world of UVGAMING! More than 1,000 games await you for an immersive and fun experience 🌟. Climb the rankings 📈 and be among the top 05 to receive exclusive rewards 🏆. play without limits and aim for the top of the leaderboard 🔝!
                            </span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail3">
                            <img src="/images/login_img_new.png" alt="UVGames" />
                        </div>

                        { flow==="PACK" ? (
                        <> 
                            <h3 className="header-title-green mb-3 mt-3 text-center">Select subscription plan</h3>                        
                            <div className="text-center radio">
                                <label>
                                    <input type="radio" id="rd1" value="1" checked={plan === 1} onChange={() => handleRadioChange(1)} />
                                    k2/Day
                                </label>
                                <label>
                                    <input type="radio" id="rd2" value="2" checked={plan === 2} onChange={() => handleRadioChange(2)} />
                                    k5/Week
                                </label>
                                <label>
                                    <input type="radio" id="rd2" value="3" checked={plan === 3} onChange={() => handleRadioChange(3)} />
                                    k15/Month
                                </label>
                            </div>
                            <div className="text-center phone-info">
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={()=>setFlow('CONFIRM')}>Subscribe</button>}
                            </div>
                        </> 
                        ): flow === "OTPCONFIRM" ?(
                        <>
                             <h3 className="login-title mb-3 mt-3 text-center">Enter OTP</h3>
                             <div className="phone-info">
                                <div className="otp_section">               
                                    <OtpInput
                                        value={otp}
                                        numInputs={4}
                                        renderInput={(props, index) => (
                                            <input   {...props}  type="tel" inputMode="numeric" />
                                        )}
                                        inputStyle="form-control form-otp"
                                        onChange={handleNumInputsChange}
                                    />
                                </div>
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmOtp} disabled={ButtonDisabled}>Confirm</button>}
                                <div className="resend_otp"><a className="send_otp_link" onClick={() => {cancel('')}}> &lt;&lt;&nbsp;Back</a></div>
                            </div>
                        </>
                         ):flow === "CONFIRM" ?(
                            <>
                                 <h3 className="login-title mb-3 mt-3 text-center">You are about to register for the UVGAMING service for <span className="span-bold">{price[plan]}</span> automatically renewable</h3>
                                 <h3 className="login-title mb-3 mt-3 text-center">Are you sure you want to continue?</h3>
                                 {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}  
                                 {loading ? <Loader />:<div id="cta_button"><button className="btn btn-primary btn-pm-green" type="button"  onClick={handleButtonClick} >YES</button></div>}
                                 <button className="btn btn-primary btn-pm-red" type="button" onClick={()=>setFlow('')}>NO</button>
                                
                            </>
                        ):flow === "SUCCESS" ?(
                        <>
                            {alreadySub?
                            <h3 className="login-title-green mb-3 mt-3 text-center">You are already subscribed on service.</h3>
                            : <h3 className="login-title-green mb-3 mt-3 text-center">You have received a subscription confirmation message on your mobile.Please confirm your subscription.</h3>
                            }
                            <h3 className="login-title mb-3 mt-3 text-center">To access the service, click on below 'Click Here' button</h3>
                            {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={() => {redirectTo('/home')}}>Click Here</button>}                          
                        
                        </>                        
                        ): (
                            <>    
                                <h3 className="login-title mt-3 text-center">Welcome to UVGAMING</h3>                               
                                <div className="phone-input">  
                                    <div className="has-validation input-group">
                                        <div className="dialing-code">
                                            <img src={"https://flagcdn.com/"+flag+".svg"} width="24" alt="Cameroon" />
                                            <span>+{countryCode}</span></div>
                                            <input                                    
                                            className="form-control form-mobile-no" 
                                            type="number" 
                                            inputMode="numeric" 
                                            value={phone?phone:''}
                                            onChange={handleNumberChange}
                                            placeholder="Enter Mobile Number"
                                        />             
                                        </div>                              
                                    {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}                              
                                    {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={sendOtp} disabled={ButtonDisabled}>Submit</button>}
                                </div>
                            </>
                        )}
                    </div>                    
                </div>
            </div>
        </div>
        </>
    )
}

export default Mtnz