import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Main from '../components/bwa/main';
import Sub from "../components/bwa/sub";
import Otp from "../components/bwa/otp";
import SubSl from "../components/sl/sub";
import OtpSl from "../components/sl/otp";
import Mtnz from "../components/register/mtnz";
import Mtnet from "../components/register/mtnet";
import Lba from "../components/register/lba";
import {service} from "../utils/constant";
import Success from '../components/success';
import Login from '../components/login/login';
import Home from '../components/home';


const OpenRoute = () => {
    if(service==="bwa") //############ Botswana ###########
    {        
        return (                
            <Routes>
                <Route path='/' element={<Sub/>} /> 
                <Route path='/main' element={<Main/>} /> 
                <Route path='/sub' element={<Sub/>}  />                
                <Route path='/otp' element={<Otp/>} />
                <Route path='/login' element={<Sub/>}  />             
                <Route path='*' element={<Navigate to="/sub" />} /> 
            </Routes>
        )
    }
 
    else if(service==="sl") //########## SIERRALEONE ############
    { 
        return (
        
            <Routes>
                <Route path='/' element={<SubSl/>} /> 
                <Route path='/sub' element={<SubSl/>}/>   
                <Route path='/otp' element={<OtpSl/>} />  
                <Route path='/success' element={<Success/>} /> 
                <Route path='/login' element={<Login/>} />    
                <Route path='*' element={<Navigate to="/sub" />} /> 
            </Routes>
        )
    }
    else if(service==="lba") //########## Liberia ############
    { 
        return (
            <Routes>
                <Route exact path='/' element={<Lba/>} /> 
                <Route path='/home' element={<Home/>} />             
                <Route path='/register' element={<Lba/>}  />
                <Route path='/login' element={<Lba/>} />
                <Route path='*' element={<Navigate to="/register" />} /> 
           </Routes>
        )
    }
    else if(service==="mtnz") //########## SIERRALEONE ############
    { 
        return (        
           
             <Routes>
             <Route exact path='/' element={<Home/>} /> 
             <Route path='/home' element={<Home/>} />             
             <Route path='/register' element={<Mtnz/>}  />
             <Route path='/login' element={<Mtnz/>} />
             <Route path='*' element={<Navigate to="/register" />} /> 
         </Routes>
        )
    }

    else if(service==="mtnet") //########## SIERRALEONE ############
    { 
        return (        
           
             <Routes>
             <Route exact path='/' element={<Home/>} /> 
             <Route path='/home' element={<Home/>} />             
             <Route path='/register' element={<Mtnet/>}  />
             <Route path='/login' element={<Mtnet/>} />
             <Route path='*' element={<Navigate to="/register" />} /> 
         </Routes>
        )
    }

};

export default OpenRoute;